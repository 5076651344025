export var forms = function forms() {
  
    //on page refresh 
  
    document.addEventListener("DOMContentLoaded", function() {

      //ON page load check if textarea in .gform-body has value and add class hidden to label
      $('.gform-body textarea').each(function() {
          if ($(this).val() != '') {
              $(this).parent().parent().find('label').addClass('hidden');
          }
      });

      //ON page load check if select in .gform-body has value and add class hidden to label
      $('.gform-body select').each(function() {
          if ($(this).val() != '') {
              $(this).parent().parent().find('label').addClass('hidden');
          }
      });
      
      //ON page load check if input type text in .gform-body has value and add class hidden to label
      $('.gform-body input[type="text"]').each(function() {
          if ($(this).val() != '') {
              $(this).parent().parent().find('label').addClass('hidden');
          }
      });

      //ON page load check if input type email in .gform-body has value and add class hidden to label
      $('.gform-body input[type="email"]').each(function() {
          if ($(this).val() != '') {
              $(this).parent().parent().find('label').addClass('hidden');
          }
      });

      //ON page load check if input type tel in .gform-body has value and add class hidden to label
      $('.gform-body input[type="tel"]').each(function() {
          if ($(this).val() != '') {
              $(this).parent().parent().find('label').addClass('hidden');
          }
      });

    



    $('input').on('focusin', function() {
        $(this).parent().parent().find('label').addClass('hidden');

      });
       
      $('input').on('focusout', function() {
        if (!this.value) {
          $(this).parent().parent().find('label').removeClass('hidden');
        }
    });

    $('textarea').on('focusin', function() {
        $(this).parent().parent().find('label').addClass('hidden');
      });
       
      $('textarea').on('focusout', function() {
        if (!this.value) {
          $(this).parent().parent().find('label').removeClass('hidden');
        }
    });

    $('select').on('focusin', function() {
        $(this).parent().parent().find('label').addClass('hidden');
      });
       
      $('select').on('focusout', function() {
        if (!this.value) {
          $(this).parent().parent().find('label').removeClass('hidden');
        }
    });

    //Remove content from .gf_placeholder class option in select
    $('.gf_placeholder').html('');

  });


  $(document).ready(function() {
    // Fonction pour vérifier les interactions avec un gestionnaire de mots de passe
    function checkPasswordManagerInteraction(selector) {
        var initialVal = $(selector).val();

        setTimeout(function() {
            var currentVal = $(selector).val();
            if (initialVal !== currentVal && currentVal !== '') {
                $(selector).parent().parent().find('label').addClass('hidden');
            }
        }, 1000); // Vérifie après 1 seconde (vous pouvez ajuster ce délai)
    }

    // Sélectionner les champs pertinents
    var fieldsToCheck = ['input[type="password"]', 'input[type="email"]', 'input[type="text"]'];

    // Ajouter les événements focusin et focusout pour cacher et montrer les labels
    $('input, textarea, select').on('focusin', function() {
        $(this).parent().parent().find('label').addClass('hidden');
    });

    $('input, textarea, select').on('focusout', function() {
        if (!this.value) {
            $(this).parent().parent().find('label').removeClass('hidden');
        }
    });

    // Surveiller les modifications des champs pertinents
    fieldsToCheck.forEach(function(selector) {
        $(selector).on('input change', function() {
            checkPasswordManagerInteraction(this);
        });

        // Vérifier initialement après le chargement de la page
        checkPasswordManagerInteraction(selector);
    });

    // Surveiller initialement les textarea et select également pour la détection des gestionnaires de mots de passe
    $('textarea, select').each(function() {
        checkPasswordManagerInteraction(this);
    });
});

};