import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';


export var slider = function slider() {

    const sliderExp = new Swiper('.slider-expertises', {
        modules: [ Pagination, Navigation ],
        loop: false,
        slidesPerView: 1,
        spaceBetween: 100,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      });


      var sideButtons = document.querySelectorAll('.side-button');

      // Créez un objet pour mapper les indices de diapositives aux boutons correspondants
      var slideButtonMapping = {};

      // Associez chaque bouton à son indice de diapositive
      sideButtons.forEach(function (button) {
        var slideIndex = parseInt(button.getAttribute('data-slide-index'));
        slideButtonMapping[slideIndex] = button;
      });
      
      // Activez le premier bouton par défaut
      var initialButton = slideButtonMapping[0];
      if (initialButton) {
        initialButton.classList.add('active');
      }

      sliderExp.on('slideChange', function () {
        // Retirez la classe active de tous les boutons
        sideButtons.forEach(function (button) {
          button.classList.remove('active');
        });
      
        // Ajoutez la classe active au bouton correspondant à la diapositive active
        var activeSlideIndex = this.realIndex;
        
        // Utilisez l'objet pour obtenir le bouton correspondant à la diapositive
        var activeButton = slideButtonMapping[activeSlideIndex];
        
        // Ajoutez la classe active au bouton
        if (activeButton) {
          activeButton.classList.add('active');
        }
      });
      
      
      sideButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          // Retirez la classe active de tous les boutons
          sideButtons.forEach(function (button) {
            button.classList.remove('active');
          });
          var slideIndex = parseInt(button.getAttribute('data-slide-index'));

          sliderExp.slideTo(slideIndex); // Changez à la diapositive correspondante
        });
      });

      var swiperpartner = new Swiper('.swiper-partner', {
        modules: [ Autoplay],
        slidesPerView: 7,
        spaceBetween: 23,
        loop: true,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 6,
          },
          1280: {
            slidesPerView: 7,
          },
        },
      });

      

      function updSwiperNumericPagination() {
        var slidesQuantity = document.querySelectorAll(".swiper-carrousel-block .swiper-slide").length;
        this.el.querySelector(".swiper-counter").innerHTML = '<span class="count">' + (this.realIndex + 1) + '</span>/<span class="total">' + slidesQuantity + "</span>";
      }      

      //load when page is ready and page is loaded
      $(document).ready(function(){
        var swiperCarrousel = new Swiper('.swiper-carrousel-block', {
          modules: [ Pagination, Navigation ],
          slidesPerView: 1,
          spaceBetween: 24,
          loop: true,
          pagination: {
            el: '.swiper-pagination-carrousel',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 3,
            },
          
          },
          allowSlideNext: true,
          allowSlidePrev: true,
          on: {
            init: updSwiperNumericPagination,
            slideChange: updSwiperNumericPagination
          }
        });
      });
      

};