export var jobboard = function jobboard() {
    
    if (!document.body.classList.contains('page-template-template-jobboard')) {
        return;
    }

    window.onload = function() {

        var pathname = window.location.pathname;
        var unit =  pathname.includes("/fr/") ? "ans" : "years";

        $('.facetwp-facet-jobboard_experience .fs-option-label').each(function() {
            var text = $(this).text().trim();
            var match = text.match(/^(\d+)\s*(.*)$/);
            if (match !== null && match.length === 3) {
                var updatedText = match[1] + ' ' + unit + ' ' + match[2];
                $(this).text(updatedText);
            }
        });

    }
}