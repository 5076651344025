import { disableBodyScroll } from 'body-scroll-lock';
import { enableBodyScroll } from 'body-scroll-lock';

export var menu = function menu() {

    //const targetElement = document.querySelector('body');

    window.addEventListener("DOMContentLoaded", (e) => {
        const hamburger = document.querySelector('.hamburger');
        if (hamburger) {
            hamburger.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector('.hamburger').classList.toggle('is-active');
                document.querySelector('.nav-mobile #menu-menu-principal').classList.toggle('open');
        
                //Chek if menu is open
                if(document.querySelector('.nav-mobile #menu-menu-principal').classList.contains('open')){
                    // disableBodyScroll(targetElement);
                    document.body.style.overflow = 'hidden';
                    $('.lang-select-mobile').show();
        
                }
                else{
                    // enableBodyScroll(targetElement);
                    document.body.style.overflow = '';
                    $('.lang-select-mobile').hide();
                }
        
            }) 
        }
    });

    $('.lang-select-mobile label').click(function(e) {
        e.preventDefault();
        $('.lang-select-mobile').toggleClass('open');
    });

    $('.nav-mobile #menu-menu-principal .menu-item-has-children > a').click(function(e) {
        e.preventDefault(); 

        if(window.innerWidth < 1280){   
            $(this).toggleClass('open-menu');
            $(this).parent().find('.sub-menu').slideToggle();
        }
    });

    /** 
     * Close menu when resizing window
     */ 
    $(window).resize(function(){
        if(window.innerWidth > 1280){   
            document.querySelector('.hamburger').classList.remove('is-active');
            document.querySelector('.nav-mobile #menu-menu-principal').classList.remove('open');

            //Chek if menu is open
            if(document.querySelector('.nav-mobile #menu-menu-principal').classList.contains('open')){
                // disableBodyScroll(targetElement);
                document.body.style.overflow = 'hidden';
                $('.lang-select-mobile').show();
            }
            else{
                // enableBodyScroll(targetElement);
                document.body.style.overflow = '';
                $('.lang-select-mobile').hide();
            }
        }
    });

    /** 
     * Moving border under menu items
     */ 
    var menuItems = document.querySelectorAll("#menu-menu-principal li");
    var border = document.querySelector(".nav-border");

    for (var i = 0; i < menuItems.length; i++) {
        menuItems[i].addEventListener("mouseover", function() {
          var itemRect = this.getBoundingClientRect();
          var menuRect = document.body.getBoundingClientRect();
          var leftPos = itemRect.left - menuRect.left;
          var width = itemRect.width;
    
          border.style.transform = "translateX(" + leftPos + "px)";
          border.style.width = width + "px";
        });
        menuItems[i].addEventListener("mouseout", function() {
            //   border.style.width = '0px';
            //   border.style.transform = "translateX(" + 80 + "px)";
        });
      }




    /** 
     * Gestion des menus du footer
     */ 
    // $('.about-footer p').click(function(e) {
    //     e.preventDefault(); 

    //     if(window.innerWidth < 768){   
    //         $(this).toggleClass('closed');
    //         $('.menu-about-container').slideToggle();
    //     }
    // });

    // $('.expertises-footer p').click(function(e) {
    //     e.preventDefault();

    //     if(window.innerWidth < 768){  
    //         $(this).toggleClass('closed');
    //         $('.menu-expertises-container').slideToggle();
    //     }
    // });

    // $(window).resize(function(){
    //     if(window.innerWidth > 768){   
    //         $('.expertises-footer p').removeClass('closed');
    //         $('.menu-expertises-container').show();

    //         $('.about-footer p').removeClass('closed');
    //         $('.menu-about-container').show();
    //     }
    // });
    
};