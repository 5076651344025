import domReady from '@roots/sage/client/dom-ready';
import * as LottiePlayer from "@lottiefiles/lottie-player";

// Import modules
import { backTop } from '@scripts/modules/backTop';
import { blog } from '@scripts/modules/blog';
import { forms } from '@scripts/modules/forms';
import { jobboard } from './modules/jobboard';
import { menu } from '@scripts/modules/menu';
import { slider } from '@scripts/modules/slider';

/**
 * Application entrypoint
 */
domReady(async () => {
  forms();
  jobboard();
  slider();
  backTop();
  blog();
  menu();
  
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
