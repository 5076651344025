export var blog = function blog() {
    if (!document.body.classList.contains('blog')) {
        return;
    }

    var sticky = document.querySelector('.sticky-post');

    //ON sticky post hover add class to the post image
    if (sticky) {
        console.log('sticky', sticky);
        sticky.addEventListener('mouseover', function () {
            var postImage = $('.sticky .thumbnail-sticky');
            postImage.addClass('hover');
        });
        sticky.addEventListener('mouseout', function () {
            var postImage = $('.sticky .thumbnail-sticky');
            postImage.removeClass('hover');
        });
    }
}